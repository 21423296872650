import slick from "slick-carousel";
import fancybox from "@fancyapps/fancybox";

$(function(){ 
    /* slider */
    $("#slider").slick({
        autoplay: true,
        autoplaySpeed: 8000,
        pauseOnFocus: false,
        pauseOnHover: false,
        speed: 1500,
        arrows: false,
        dots: false,
        centerMode: true,
        fade: true,
    });
    
    /* FANCYBOX */
     $("a.fancybox").fancybox({
         loop : true,
         keyboard : true,
     });
});
